import styled, { css } from "styled-components";
import {
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledHiveTechCrowdSectionHeader = styled.div`
  padding: ${Rem(50)} ${Rem(20)} ${Rem(30)};
  color: ${Colors.white};
`;

export const StyledHiveTechCrowdSectionHeaderTitle = styled.h1`
  text-align: center;
  font-size: ${Rem(40)};
`;

export const StyledHiveTechCrowdSectionHeaderSubtitle = styled.h3`
  text-align: center;
  font-size: ${Rem(25)};
  padding-top: ${Rem(25)};
`;
